define('ember-toolbars/components/fixed-within-toolbars', ['exports', 'ember', 'ember-toolbars/templates/components/fixed-within-toolbars'], function (exports, _ember, _emberToolbarsTemplatesComponentsFixedWithinToolbars) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberToolbarsTemplatesComponentsFixedWithinToolbars['default'],
    tagName: '',
    style: _ember['default'].computed('right', 'top', 'left', function () {
      var _this = this;

      var style = "position: absolute;";
      ['top', 'left', 'right'].forEach(function (field) {
        var value = String(_this.get(field));

        // safety check -- we're going to use htmlSafe to assert that we
        // haven't introduced any injections. So we're responsible for
        // only embedding valid things.
        if (!/^\d+(px|em|ex|vw|vh)?$/.test(value)) {
          value = null;
        }

        if (value != null) {
          style += field + ': ' + value + ';';
        }
      });
      return _ember['default'].String.htmlSafe(style);
    }),
    innerStyle: _ember['default'].computed('right', function () {
      var style = "position: fixed;";
      var hasRight = this.get('right') != null;
      if (hasRight) {
        style += "transform: translateX(-100%)";
      }
      return _ember['default'].String.htmlSafe(style);
    })
  });
});