define('ember-cli-crudities/components/form-foreignkey', ['exports', 'ember-cli-crudities/templates/components/form-foreignkey', 'ember-cli-crudities/components/foreignkey-base', 'ember-cli-crudities/mixins/boundvalue'], function (exports, _formForeignkey, _foreignkeyBase, _boundvalue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _foreignkeyBase.default.extend(_boundvalue.default, {
    layout: _formForeignkey.default,
    type: 'foreignkey',
    isExpanded: false,

    _placeholder: '---',
    placeHolder: Ember.computed.or('placeholder', '_placeholder')
  });
});