define('ember-cli-crudities/components/form-field', ['exports', 'ember-cli-crudities/templates/components/form-field'], function (exports, _formField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formField.default,
    store: Ember.inject.service(),

    tagName: null,
    formLayout: 'horizontal',
    horiClass: 'col-sm-3',
    inputClass: 'col-sm-9',
    is_ready: false,

    isFieldset: Ember.computed.equal('field.widget', 'fieldset'),
    isTabset: Ember.computed.equal('field.widget', 'tabset'),

    isToManyTable: Ember.computed.equal('field.widget', 'tomany-table'),
    isToManySlide: Ember.computed.equal('field.widget', 'tomany-slide'),
    isToManyStack: Ember.computed.equal('field.widget', 'tomany-stack'),

    isToMany: Ember.computed.or('isToManyTable', 'isToManySlide', 'isToManyStack'),

    isCollection: Ember.computed.or('isFieldset', 'isTabset', 'isToMany'),

    widgetComponent: Ember.computed('field.widget', function () {
      var widget = this.get('field.widget');
      if (widget) {
        return 'form-' + widget;
      }
      return 'form-input';
    }),

    klass: Ember.computed.or('field.class', 'innerClass'),

    field_horiClass: Ember.computed.or('{field.horiClass,horiClass}'),
    field_inputClass: Ember.computed.or('{field.inputClass,inputClass}'),

    label: Ember.computed.alias('field.label'),
    property: Ember.computed.alias('field.name'),

    record: Ember.computed('model', 'model.isFulfilled', 'field.name', function () {
      var model = this.get('model');
      var field_name = this.get('field.name');
      if (field_name !== undefined) {
        var rv = model.get(field_name);
        return rv;
      }
      return model;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.get('model') !== null && this.get('model').hasOwnProperty('isFulfilled')) {
        this.get('model').then(function (m) {
          if (!Ember.isEmpty(_this.get('field.extra.related_name'))) {
            var store = _this.get('store');
            var field_name = _this.get('field.name');
            _this.set('inverse', m.inverseFor(field_name, store));
          }
          _this.set('is_ready', true);
        });
      } else {
        this.set('is_ready', true);
      }
    },
    onChange: function onChange() {
      // Do nothing
    }
  });
});