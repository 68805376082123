define('ember-cli-crudities/components/foreignkey-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,

    store: Ember.inject.service(),

    autoComplete: false,
    _label: '__str__',
    choiceLabel: Ember.computed.or('extra.label', '_label'),
    _search: 'backend',
    search: Ember.computed.or('extra.search', '_search'),

    choices: Ember.computed('content', 'content.@each.isSoftDeleted', function () {
      var content = this.get('content');
      var values = new Ember.A();
      if (content) {
        content.map(function (item) {
          if (!item.get('isSoftDeleted')) {
            values.pushObject(item);
          }
        });
      }
      return values;
    }),

    init: function init() {
      this._super();
      if (!this.get('readonly')) {
        var property = this.get('extra.property_path');
        var rv = void 0;
        if (property !== undefined) {
          this.set('content', this.get('model.' + property));
          return;
        } else if (this.get('extra.dontSeed')) {
          rv = new Ember.A();
        } else {
          var model_name = this.get('extra.related_model');
          var query = this.get('extra.query');
          if (query !== undefined) {
            rv = this.get('store').query(model_name, query);
          } else {
            rv = this.get('store').findAll(model_name);
          }
        }
        this.set('_content', rv);
        this.set('content', rv);
      }
    },
    _clean: function _clean() {
      // placeholder
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var model = this.get('model');
      var property = this.get('extra.property_path');
      if (property) {
        model.addObserver(property, this._do_search.bind(this, undefined));
      }
      var filters = this.get('extra.dynamicFilters');
      if (filters) {
        var reload = false;
        filters.forEach(function (filter) {
          model.addObserver(filter.property_path, _this._do_search.bind(_this, undefined, true));
          if (!Ember.isEmpty(_this.get('model.' + filter.property_path))) {
            reload = true;
          }
        });
        if (reload === true && !this.get('extra.dontSeed')) {
          this._do_search(undefined);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      var model = this.get('model');
      var property = this.get('extra.property_path');
      if (property) {
        model.removeObserver(property, this._do_search.bind(this, undefined));
      }
      var filters = this.get('extra.dynamicFilters');
      if (filters) {
        filters.forEach(function (filter) {
          model.removeObserver(filter.property_path, _this2._do_search.bind(_this2, undefined));
        });
      }
    },
    _do_search: function _do_search(term, clean) {
      var _this3 = this;

      var property = this.get('extra.property_path');
      var content = Ember.A();
      if (property !== undefined) {
        content = this.get('model.' + property);
        if (content !== undefined && term) {
          content = content.filter(function (item) {
            var str = item.get(_this3.get('choiceLabel'));
            if (str) {
              return str.toLowerCase().indexOf(term.toLowerCase()) > -1;
            }
            return false;
          });
        } else if (content === undefined) {
          content = new Ember.A();
        }
      } else if (this.get('search') === 'simple') {
        content = this.get('_content');
        if (term) {
          content = content.filter(function (item) {
            var str = item.get(_this3.get('choiceLabel'));
            if (str) {
              return str.toLowerCase().indexOf(term.toLowerCase()) > -1;
            }
            return false;
          });
        }
      } else {
        var model_name = this.get('extra.related_model');
        var query = this.get('extra.query');
        if (query === undefined) {
          query = {};
        }
        query['search'] = term;
        var filters = this.get('extra.dynamicFilters');
        if (filters) {
          filters.forEach(function (filter) {
            var value = _this3.get('model.' + filter.property_path);
            if (value !== undefined) {
              query[filter.filter] = value;
            }
          });
        }
        content = this.get('store').query(model_name, query);
      }
      if (this.get('search') === 'simple' || property !== undefined) {
        (function () {
          var query = _this3.get('extra.query');
          var deffered = false;
          if (query) {
            var _loop = function _loop(prop) {
              if (query.hasOwnProperty(prop)) {
                content = content.filter(function (item) {
                  if (!item.get('isLoaded')) {
                    if (!deffered) {
                      Ember.run.later(_this3._do_search.bind(_this3, term), 150);
                      deffered = true;
                    }
                    return true;
                  }
                  var val = item.get(prop);
                  return val === query[prop];
                });
              }
            };

            for (var prop in query) {
              _loop(prop);
            }
          }
          var filters = _this3.get('extra.dynamicFilters');
          if (filters) {
            filters.forEach(function (filter) {
              var val = _this3.get('model.' + filter.property_path);
              if (val !== undefined) {
                content = content.filter(function (item) {
                  if (!item.get('isLoaded')) {
                    if (!deffered) {
                      Ember.run.later(_this3._do_search.bind(_this3, term), 150);
                      deffered = true;
                    }
                    return true;
                  }
                  return item.get(filter.remote_property_path) === val;
                });
              }
            });
          }
        })();
      }
      // content = content.filterBy('isSoftDeleted', false);
      if (!this.get('isDestroyed')) {
        this.set('content', content);
        if (clean === true) {
          this._clean(content);
        }
      }
      return content;
    },


    linkApp: Ember.computed('extra', 'extra.related_model', function () {
      var related = this.get('extra.related_model');
      return related.split('/').shift();
    }),

    linkModel: Ember.computed('extra', 'extra.related_model', function () {
      var related = this.get('extra.related_model');
      return related.split('/').pop();
    }),

    actions: {
      change: function change(value) {
        this.set('value', value);
        this.onChange(value);
      },
      search: function search(term) {
        return this._do_search(term);
      },
      validate: function validate(parent) {
        var value = this.get('value');
        if (value !== undefined && value.hasOwnProperty('isFulfilled')) {
          value.then(function () {
            parent.validate();
          });
        } else {
          parent.validate();
        }
      }
    }
  });
});