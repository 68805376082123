define('ember-cli-crudities/components/record-line', ['exports', 'ember-cli-crudities/templates/components/record-line', 'ember-sortable/mixins/sortable-item', 'ember-cli-crudities/mixins/with-custom-actions', 'ember-cli-crudities/utils/conditional-format'], function (exports, _recordLine, _sortableItem, _withCustomActions, _conditionalFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sortableItem.default, _withCustomActions.default, {
    layout: _recordLine.default,
    tagName: 'tr',
    classNameBindings: ['state', 'conditionalFormatting'],
    timer: undefined,
    handle: '#none',

    state: '',
    conditionalFormatting: Ember.computed(function () {
      var model = this.get('model');
      var conditions = this.get('conditionalFormat');
      return (0, _conditionalFormat.default)(model, conditions);
    }).volatile(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.attrs.parent !== undefined) {
        var fields = ['display_fields', 'innerClass', 'changeFormRoute', 'app_name', 'label', 'orderable', 'custom_actions'];
        fields.forEach(function (field) {
          if (_this.attrs[field] === undefined) {
            _this.set(field, _this.get('parent.' + field));
          }
        });
      }
    },


    actions: {
      addNew: function addNew() {},
      placeHolder: function placeHolder() {},
      save: function save() /* model, field */{
        var _this2 = this;

        Ember.run.debounce(this, function () {
          _this2.get('model').save().then(function () {
            if (_this2.timer !== undefined) {
              Ember.run.cancel(_this2.timer);
            }
            _this2.set('state', 'success');
            _this2.timer = Ember.run.later(_this2, function () {
              _this2.set('state', '');
            }, 750);
          }).catch(function (err) {
            /* eslint-disable no-console */
            console.error(err);
            /* eslint-enable no-console */
            if (_this2.timer !== undefined) {
              Ember.run.cancel(_this2.timer);
            }
            _this2.set('state', 'danger');
            _this2.timer = Ember.run.later(_this2, function () {
              if (!_this2.get('isDestroyed')) {
                _this2.set('state', '');
              }
            }, 750);
          });
        }, 250);
      }
    }
  });
});