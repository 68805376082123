define('ember-data-change-tracker/transforms/object', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value && JSON.stringify(value);
    },

    deserialize: function deserialize(value) {
      if (_ember.default.isEmpty(value)) {
        return {};
      }
      if (_ember.default.typeOf(value) === "object") {
        return value;
      }
      if (_ember.default.typeOf(value) === 'string') {
        return JSON.parse(value);
      }
    }
  });
});