define('ember-cli-crudities/helpers/evaluate-condition', ['exports', 'ember-cli-crudities/utils/evaluator'], function (exports, _evaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.evaluateCondition = evaluateCondition;
  function evaluateCondition(params /*, hash*/) {
    var condition = params[0];
    var model = params[1];
    if (condition === undefined) {
      return false;
    }

    if (model === undefined) {
      return false;
    }
    var value = model.get(condition.property_path);
    var rv = (0, _evaluator.default)(condition, value);
    return rv;
  }

  exports.default = Ember.Helper.helper(evaluateCondition);
});