define('ember-cli-crudities/components/base-widget', ['exports', 'ember-cli-crudities/templates/components/base-widget', 'ember-cli-crudities/mixins/boundvalue', 'ember-cli-crudities/utils/validation'], function (exports, _baseWidget, _boundvalue, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_boundvalue.default, {
    layout: _baseWidget.default,

    classNames: ['form-group'],
    classNameBindings: ['field.translated:translated', 'hasError', 'required', 'readonly'],

    label: null,
    type: 'text',
    horiClass: 'col-sm-3',
    inputClass: 'col-sm-9',
    errors: [],
    validations: {},

    isInput: Ember.computed('type', function () {
      var type = this.get('type');
      if (-1 !== ['text', 'tel', 'email', 'number', 'date', 'time', 'datetime', 'color', 'month', 'week', 'url', 'password'].indexOf(type)) {
        return true;
      }
      return false;
    }),

    isDateTime: Ember.computed('type', function () {
      var type = this.get('type');
      if (-1 !== ['date', 'time', 'datetime', 'month', 'week'].indexOf(type)) {
        return true;
      }
      return false;
    }),

    momentFormat: Ember.computed('type', function () {
      var type = this.get('type');
      if (type === 'date') {
        return 'ddd YYYY-MM-DD';
      }
      if (type === 'time') {
        return 'kk:mm:ss';
      }
      return 'ddd YYYY-MM-DD HH:mm:ss zz';
    }),

    hasLabel: Ember.computed('label', 'formLayout', 'type', function () {
      var formLayout = this.get('formLayout');
      var label = this.get('label');
      var type = this.get('type');
      return !Ember.isEmpty(label) && formLayout === 'horizontal' && type !== 'checkbox';
    }),

    bsInputClass: Ember.computed('inputClass', 'hasLabel', function () {
      var hasLabel = this.get('hasLabel');
      if (!hasLabel) {
        return 'col-xs-12';
      }
      return this.get('inputClass');
    }),

    buildValidator: function buildValidator() {
      var validations = this.get('validations');
      if (this.get('required')) {
        Ember.set(validations, 'presence', true);
      }
      if (this.get('model.form_errors') === undefined) {
        this.set('model.form_errors', {});
      }
      var validator = _validation.default.create({ validations: validations });
      this.set('validator', validator);
      return validator;
    },


    status: Ember.computed('model.isError', 'model.errors.@each.length', 'errors', 'model.form_errors.@each.length', function () {
      if (this.get('model.errors.' + this.get('property') + '.length')) {
        return 'error';
      }
      if (this.get('model.form_errors.' + this.get('property') + '.length')) {
        return 'error';
      }
      if (this.get('errors.length')) {
        return 'error';
      }
      return 'success';
    }),

    hasError: Ember.computed.equal('status', 'error'),

    validate: function validate() {
      var validator = this.get('validator');
      var value = this.get('model.' + this.get('property'));

      if (validator === undefined) {
        validator = this.buildValidator();
      }
      if (validator !== undefined) {
        validator.validate(value);

        this.set('errors', validator.get('errors'));
        this.set('model.form_errors.' + this.get('property'), validator.get('errors'));
        return validator.get('isValid');
      }
      return true;
    },


    actions: {
      // for sub-classes
      validate: function validate(parent) {
        parent.validate();
      }
    }
  });
});