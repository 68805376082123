define('ember-toolbars/transition-helpers', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  exports.waitForPrevious = waitForPrevious;
  exports.propertiesFor = propertiesFor;
  exports.marginAdjustment = marginAdjustment;
  exports.biggestSize = biggestSize;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function waitForPrevious(context, name) {
    if ((0, _liquidFire.isAnimating)(context.oldElement, name)) {
      return (0, _liquidFire.finish)(context.oldElement, name);
    } else {
      (0, _liquidFire.stop)(context.oldElement);
      return _liquidFire.Promise.resolve();
    }
  }

  function propertiesFor(dimension) {
    if (dimension.toLowerCase() === 'x') {
      return {
        property: 'translateX',
        measure: 'width'
      };
    } else {
      return {
        property: 'translateY',
        measure: 'height'
      };
    }
  }

  function marginAdjustment(context, measure, _opts) {
    var opts = Object.assign({
      queue: false
    }, _opts);
    var targets = opts.adjust;
    if (!targets) {
      return function () {
        return _liquidFire.Promise.resolve();
      };
    }
    var distance = 0;
    if (context.newElement) {
      distance = parseInt(context.newElement.css(measure), 10);
    }
    return function () {
      return _liquidFire.Promise.all(targets.map(function (target) {
        return (0, _liquidFire.animate)(target.element, _defineProperty({}, target.property, distance + 'px'), opts);
      }));
    };
  }

  function biggestSize(context, dimension) {
    var sizes = [];
    if (context.newElement) {
      sizes.push(parseInt(context.newElement.css(dimension), 10));
      sizes.push(parseInt(context.newElement.parent().css(dimension), 10));
    }
    if (context.oldElement) {
      sizes.push(parseInt(context.oldElement.css(dimension), 10));
      sizes.push(parseInt(context.oldElement.parent().css(dimension), 10));
    }
    return Math.max.apply(null, sizes);
  }
});