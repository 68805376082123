define('ember-toolbars/transitions/adapt-margin', ['exports', 'ember-toolbars/transition-helpers', 'liquid-fire'], function (exports, _emberToolbarsTransitionHelpers, _liquidFire) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = function (dimension, opts) {
    var _this = this;

    if (this.newElement) {
      if (opts && opts.adjust) {
        (function () {
          var _propertiesFor = (0, _emberToolbarsTransitionHelpers.propertiesFor)(dimension);

          var measure = _propertiesFor.measure;

          var size = parseInt(_this.newElement.css(measure), 10);
          opts.adjust.forEach(function (_ref) {
            var element = _ref.element;
            var property = _ref.property;

            (0, _liquidFire.animate)(element, _defineProperty({}, property, size + 'px'), { duration: 0, queue: false });
          });
        })();
      }
      this.newElement.css({ visibility: '' });
    }
  };
});