define('ember-cli-crudities/components/cf-buttons', ['exports', 'ember-cli-crudities/templates/components/cf-buttons'], function (exports, _cfButtons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _cfButtons.default,
    tagName: ''
  });
});