define('ember-toolbars/transitions/move-over', ['exports', 'liquid-fire', 'ember-toolbars/transition-helpers'], function (exports, _liquidFire, _emberToolbarsTransitionHelpers) {
  exports['default'] = moveOver;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function moveOver(dimension, direction, opts) {
    var _this = this;

    var _propertiesFor = (0, _emberToolbarsTransitionHelpers.propertiesFor)(dimension);

    var property = _propertiesFor.property;
    var measure = _propertiesFor.measure;

    return (0, _emberToolbarsTransitionHelpers.waitForPrevious)(this, 'moving-in').then(function () {
      var bigger = (0, _emberToolbarsTransitionHelpers.biggestSize)(_this, measure);
      var animateMargin = (0, _emberToolbarsTransitionHelpers.marginAdjustment)(_this, measure, opts);
      return _liquidFire.Promise.all([animateMargin(), (0, _liquidFire.animate)(_this.oldElement, _defineProperty({}, property, bigger * direction + 'px'), opts), (0, _liquidFire.animate)(_this.newElement, _defineProperty({}, property, ["0px", -1 * bigger * direction + 'px']), opts, 'moving-in')]);
    });
  }
});