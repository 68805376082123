define('ember-cli-crudities/components/tomany-base', ['exports', 'ember-cli-crudities/components/base-collection'], function (exports, _baseCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isEmpty = Ember.isEmpty;
  exports.default = _baseCollection.default.extend({
    store: Ember.inject.service(),
    saveMePrefix: '',

    related: computed('model', 'model.length', 'model.@each.isSoftDeleted', 'extra.hideDeleted', function () {
      var value = this.get('model');
      if (!isEmpty(value)) {
        if (!this.get('extra.reload')) {
          var toReload = [];
          value.filter(function (record) {
            return record.get('isEmpty');
          }).forEach(function (record) {
            toReload.push(record.get('id'));
          });
          if (toReload.length > 0) {
            this.get('store').query(value.get('content.type.modelName'), { ids: toReload });
          }
        }
        if (this.get('extra.hideDeleted')) {
          var rv = value.filter(function (item) {
            return !item.get('isSoftDeleted');
          });
          return rv;
        }
      }
      return value;
    }),

    save: function save() {
      this.get('model').then(function (model) {
        model.forEach(function (record) {
          if (!record.get('isSoftDeleted')) {
            record.save();
          } else {
            record.destroyRecord();
          }
        });
      });
    },
    delete: function _delete() {
      this.get('model').then(function (model) {
        model.forEach(function (record) {
          record.destroyRecord();
        });
      });
    },
    getDefaults: function getDefaults() {
      var rv = Ember.copy(this.get('_defaults'));
      if (this.get('field.extra.sortableBy')) {
        rv[this.get('field.extra.sortableBy')] = parseInt(this.get('related.length')) + 1;
      }
      return rv;
    },


    model_name: Ember.computed('model', function () {
      var value = this.get('model');
      if (!isEmpty(value)) {
        return value.get('content.type.modelName');
      }
      return '';
    }),

    related_app: Ember.computed('model_name', function () {
      var name = this.get('model_name');
      if (name === '') {
        return '';
      }
      return name.split('/')[0];
    }),

    related_singular: Ember.computed('model_name', function () {
      var name = this.get('model_name');
      if (name === '') {
        return '';
      }
      name = name.split('/');
      return name[name.length - 1];
    }),

    saveMe: function saveMe() {
      if (this.get('field.name')) {
        this.get('saveMes').push('' + this.get('saveMePrefix') + this.get('field.name'));
      }
    },


    actions: {
      addRelated: function addRelated() {
        var related = this.get('model');
        var record = this.get('store').createRecord(related.get('content.type.modelName'), this.getDefaults());
        related.pushObject(record);
        this.saveMe();
      },
      popupAddRelated: function popupAddRelated(model) {
        var related = this.get('model');
        var record = this.get('store').createRecord(model, this.getDefaults());
        related.unshiftObject(record);
        this.addRelated(model, this.get('field'), false, record);
        this.saveMe();
      },
      deleteRelated: function deleteRelated(record) {
        if (record.get('isNew')) {
          record.rollbackAttributes();
        } else {
          record.set('isSoftDeleted', !record.get('isSoftDeleted'));
        }
        this.saveMe();
      }
    }

    // didInsertElement() {
    //   Ember.run.later(this, function() {
    //     if (!this.get('isDestroyed')) {
    //       this.rerender();
    //     }
    //   }, 750);
    // },
  });
});