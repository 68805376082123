define('ember-cli-crudities/utils/validation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validate_model = validate_model;


  var Validator = Ember.Object.extend({
    validations: {},
    isValid: true,
    errors: [],

    validate_presence: function validate_presence(value, options) {
      if (options !== false && value !== undefined && value.hasOwnProperty('isFulfilled')) {
        if (Ember.isBlank(value.content)) {
          return ['This field may not be blank'];
        }
      } else if (options !== false && Ember.isBlank(value)) {
        return ['This field may not be blank'];
      }
      return [];
    },
    validate_length: function validate_length(value, options) {
      var minimum = options.minimum,
          maximum = options.maximum;

      var errors = [];
      if (minimum !== undefined && Number.isInteger(minimum) && (Ember.isEmpty(value) || value.length < minimum)) {
        errors.push('This field must be at least ' + minimum + ' characters long');
      }
      if (maximum !== undefined && Number.isInteger(maximum) && !Ember.isEmpty(value) && value.length > maximum) {
        errors.push('This field must be at most ' + maximum + ' characters long');
      }
      return errors;
    },
    validate: function validate(value) {
      var validations = this.get('validations');
      var isValid = true;
      var errors = [];
      var result = void 0,
          k = void 0;

      for (k in validations) {
        if (this.get('validate_' + k) !== undefined) {
          result = this['validate_' + k](value, validations[k]);
          isValid = isValid && result.length === 0;
          result.forEach(function (error) {
            errors.push(error);
          });
        }
      }

      this.set('isValid', isValid);
      this.set('errors', errors);
      return isValid;
    }
  });

  exports.default = Validator;
  function validate_model(model, fieldset) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (fieldset === undefined) {
        fieldset = [];
      }
      var isValid = true;
      var validations = void 0;
      var validator = void 0;
      var rv = void 0;
      fieldset.forEach(function (field) {
        if (Ember.get(field, 'name') !== undefined) {
          validations = Ember.get(field, 'validations');
          if (Ember.get(field, 'required')) {
            Ember.set(validations, 'presence', true);
          }
          if (validations !== undefined) {
            validator = Validator.create({ validations: validations });
            rv = validator.validate(Ember.get(model, Ember.get(field, 'name')));
            if (model.get('form_errors') === undefined) {
              model.set('form_errors', {});
            }
            Ember.set(model, 'form_errors.' + Ember.get(field, 'name'), validator.get('errors'));
            if (!rv) {
              isValid = false;
            }
          }
        }
      });
      if (isValid) {
        resolve(model);
      } else {
        console.warn('errors found', fieldset, model.get('form_errors'));
        reject(new Error('Please correct the errors on this form'));
      }
    });
  }
});