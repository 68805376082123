define('ember-cli-crudities/components/custom-action', ['exports', 'ember-cli-crudities/templates/components/custom-action'], function (exports, _customAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _customAction.default,
    isComponent: Ember.computed('act.component', function () {
      var component = this.get('act.component');
      return component !== undefined;
    }),
    isMethod: Ember.computed('act.type', function () {
      var type = this.get('act.type');
      return type === 'modelMethod' || type === 'closureMethod';
    })
  });
});