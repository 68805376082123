define('squishable-container/components/squishable-container', ['exports', 'squishable-container/templates/components/squishable-container', 'squishable-container/raf'], function (exports, _squishableContainer, _raf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var allowedUnits = ['em', 'vw', 'px', 'rem', 'ex'];

  exports.default = Ember.Component.extend({
    layout: _squishableContainer.default,
    width: 100,
    unit: 'vw',
    classNames: ['squishable-container'],

    init: function init() {
      this._super();
      this._scale = 1;
    },


    innerStyle: Ember.computed('width', 'unit', function () {
      var width = this.get('width');
      var unit = this.get('unit');
      if (typeof width !== 'number') {
        throw new Error('squishable-container: width must be a number, not ' + width);
      }
      if (allowedUnits.indexOf(unit) === -1) {
        throw new Error('squishable-container: unit must be one of [' + allowedUnits.join(', ') + '], not ' + unit);
      }
      return Ember.String.htmlSafe('width: ' + width + unit);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _raf.requestAnimationFrame)(function () {
        return _this.updateScale();
      });
    },
    updateScale: function updateScale() {
      var _this2 = this;

      if (this.isDestroyed) {
        return;
      }
      var scale = this.$().width() / this.$().children(':first').width();
      if (Math.abs(this._scale - scale) > 0.0001) {
        this.$().css({
          transform: 'scale(' + scale + ')'
        });
        this._scale = scale;
      }
      (0, _raf.requestAnimationFrame)(function () {
        return _this2.updateScale();
      });
    }
  });
});