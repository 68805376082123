define('ember-data-change-tracker/tracker', ['exports', 'ember', 'ember-data-change-tracker/utilities'], function (exports, _ember, _utilities) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ModelTrackerKey = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var assign = _ember.default.assign || _ember.default.merge;
  var ModelTrackerKey = exports.ModelTrackerKey = '-change-tracker';
  var alreadyTrackedRegex = /^-mf-|string|boolean|date|^number$/,
      knownTrackerOpts = _ember.default.A(['only', 'auto', 'except', 'trackHasMany', 'enableIsDirty']),
      defaultOpts = { trackHasMany: true, auto: false, enableIsDirty: false };

  /**
   * Helper class for change tracking models
   */

  var Tracker = function () {
    function Tracker() {
      _classCallCheck(this, Tracker);
    }

    _createClass(Tracker, null, [{
      key: 'container',
      value: function container(model) {
        return _ember.default.getOwner ? _ember.default.getOwner(model.store) : model.store.container;
      }
    }, {
      key: 'envConfig',
      value: function envConfig(model) {
        var config = this.container(model).resolveRegistration('config:environment');
        // sometimes the config is not available ?? not sure why
        return config && config.changeTracker || {};
      }
    }, {
      key: 'modelConfig',
      value: function modelConfig(model) {
        return model.changeTracker || {};
      }
    }, {
      key: 'isAutoSaveEnabled',
      value: function isAutoSaveEnabled(model) {
        if (model.constructor.trackerAutoSave === undefined) {
          var options = this.options(model);
          model.constructor.trackerAutoSave = options.auto;
        }
        return model.constructor.trackerAutoSave;
      }
    }, {
      key: 'isIsDirtyEnabled',
      value: function isIsDirtyEnabled(model) {
        if (model.constructor.trackerEnableIsDirty === undefined) {
          var options = this.options(model);
          model.constructor.trackerEnableIsDirty = options.enableIsDirty;
        }
        return model.constructor.trackerEnableIsDirty;
      }
    }, {
      key: 'transformFn',
      value: function transformFn(model, attributeType) {
        var transformType = attributeType || 'object';
        return model.store.serializerFor(model.constructor.modelName).transformFor(transformType);
      }
    }, {
      key: 'normalize',
      value: function normalize(model, data) {
        var modelClass = model.store.modelFor(model.constructor.modelName);
        var container = this.container(model);
        var serializer = container.lookup('serializer:-rest');
        serializer.set('store', model.store);
        return serializer.normalize(modelClass, data);
      }
    }, {
      key: 'metaInfo',
      value: function metaInfo(model) {
        var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var info = model.constructor.trackerKeys || {};
        if (key) {
          return info[key];
        }
        return info;
      }
    }, {
      key: 'options',
      value: function options(model) {
        var envConfig = this.envConfig(model);
        var modelConfig = this.modelConfig(model);
        var opts = assign({}, defaultOpts, envConfig, modelConfig);

        var unknownOpts = Object.keys(opts).filter(function (v) {
          return !knownTrackerOpts.includes(v);
        });
        _ember.default.assert('[ember-data-change-tracker] changeTracker options can have\n      \'only\', \'except\' , \'auto\', \'enableIsDirty\' or \'trackHasMany\' but you are declaring: ' + unknownOpts, _ember.default.isEmpty(unknownOpts));

        return opts;
      }
    }, {
      key: 'trackingIsSetup',
      value: function trackingIsSetup(model) {
        return model.constructor.alreadySetupTrackingMeta;
      }
    }, {
      key: 'setupTracking',
      value: function setupTracking(model) {
        if (!this.trackingIsSetup(model)) {
          model.constructor.alreadySetupTrackingMeta = true;
          var info = Tracker.getTrackerInfo(model);
          model.constructor.trackerKeys = info.keyMeta;
          model.constructor.trackerAutoSave = info.autoSave;
          model.constructor.trackerEnableIsDirty = info.enableIsDirty;
        }
      }
    }, {
      key: 'getTrackerInfo',
      value: function getTrackerInfo(model) {
        var _this = this;

        var _extractKeys = this.extractKeys(model),
            _extractKeys2 = _slicedToArray(_extractKeys, 2),
            trackableInfo = _extractKeys2[0],
            hasManyList = _extractKeys2[1];

        var trackerOpts = this.options(model);

        var all = Object.keys(trackableInfo);
        var except = trackerOpts.except || [];
        var only = trackerOpts.only || [].concat(_toConsumableArray(all));

        if (!trackerOpts.trackHasMany) {
          except = [].concat(_toConsumableArray(except), _toConsumableArray(hasManyList));
        }

        all = [].concat(_toConsumableArray(all)).filter(function (a) {
          return !except.includes(a);
        });
        all = [].concat(_toConsumableArray(all)).filter(function (a) {
          return only.includes(a);
        });

        var keyMeta = {};
        Object.keys(trackableInfo).forEach(function (key) {
          if (all.includes(key)) {
            var info = trackableInfo[key];
            info.transform = _this.getTransform(model, key, info);
            keyMeta[key] = info;
          }
        });

        var enableIsDirty = trackerOpts.enableIsDirty;

        return { autoSave: trackerOpts.auto, enableIsDirty: enableIsDirty, keyMeta: keyMeta };
      }
    }, {
      key: 'extractKeys',
      value: function extractKeys(model) {
        var constructor = model.constructor;

        var trackerKeys = {};
        var hasManyList = [];

        constructor.eachAttribute(function (attribute, meta) {
          if (!alreadyTrackedRegex.test(meta.type)) {
            trackerKeys[attribute] = { type: 'attribute', name: meta.type };
          }
        });

        constructor.eachRelationship(function (key, relationship) {
          trackerKeys[key] = {
            type: relationship.kind,
            polymorphic: relationship.options.polymorphic
          };
          if (relationship.kind === 'hasMany') {
            hasManyList.push(key);
          }
        });

        return [trackerKeys, hasManyList];
      }
    }, {
      key: 'getTransform',
      value: function getTransform(model, key, info) {
        var transform = void 0;

        if (info.type === 'attribute') {
          transform = this.transformFn(model, info.name);

          _ember.default.assert('[ember-data-change-tracker] changeTracker could not find\n      a ' + info.name + ' transform function for the attribute \'' + key + '\' in\n      model \'' + model.constructor.modelName + '\'.\n      If you are in a unit test, be sure to include it in the list of needs', transform);
        } else {
          transform = _utilities.relationShipTransform[info.type];
        }

        return transform;
      }
    }, {
      key: 'didChange',
      value: function didChange(model, key, changed, info) {
        changed = changed || model.changedAttributes();
        if (changed[key]) {
          return true;
        }
        var keyInfo = info && info[key] || this.metaInfo(model, key);
        if (keyInfo) {
          var current = this.serialize(model, key, keyInfo);
          var last = this.lastValue(model, key);
          switch (keyInfo.type) {
            case 'attribute':
            case 'belongsTo':
              return (0, _utilities.didModelChange)(current, last, keyInfo.polymorphic);
            case 'hasMany':
              return (0, _utilities.didModelsChange)(current, last, keyInfo.polymorphic);
          }
        }
      }
    }, {
      key: 'serialize',
      value: function serialize(model, key, keyInfo) {
        var info = keyInfo || this.metaInfo(model, key);
        var value = void 0;
        if (info.type === 'attribute') {
          value = info.transform.serialize(model.get(key));
          if (typeof value !== 'string') {
            value = JSON.stringify(value);
          }
        } else {
          value = info.transform.serialize(model, key, info);
        }
        return value;
      }
    }, {
      key: 'lastValue',
      value: function lastValue(model, key) {
        return (model.get(ModelTrackerKey) || {})[key];
      }
    }, {
      key: 'rollbackData',
      value: function rollbackData(model, trackerInfo) {
        var _this2 = this;

        var data = { id: model.id };
        Object.keys(trackerInfo).forEach(function (key) {
          var keyInfo = trackerInfo[key];
          if (_this2.didChange(model, key, null, trackerInfo)) {
            // For now, blow away the hasMany relationship before resetting it
            // since pushing is not clearing and resetting at the moment
            // this slows down the hasMany rollback by about 25%, but still
            // fast => (~100ms) with 500 items in a hasMany
            if (keyInfo.type === 'hasMany') {
              model.set(key, []);
            }
            var lastValue = Tracker.lastValue(model, key);
            if (keyInfo.type === 'attribute' && !keyInfo.name) {
              // attr() undefined type
              lastValue = keyInfo.transform.deserialize(lastValue);
            }
            data[key] = lastValue;
          }
        });
        return data;
      }
    }, {
      key: 'saveChanges',
      value: function saveChanges(model) {
        var metaInfo = this.metaInfo(model);
        Object.keys(metaInfo).forEach(function (key) {
          Tracker.saveKey(model, key);
        });
      }
    }, {
      key: 'triggerIsDirtyReset',
      value: function triggerIsDirtyReset(model) {
        model.notifyPropertyChange('hasDirtyAttributes');
        model.notifyPropertyChange('hasDirtyRelations');
      }
    }, {
      key: 'saveKey',
      value: function saveKey(model, key) {
        var tracker = model.get(ModelTrackerKey) || {};
        tracker[key] = this.serialize(model, key);
        model.set(ModelTrackerKey, tracker);
      }
    }, {
      key: 'clear',
      value: function clear(model) {
        model.set(ModelTrackerKey, undefined);
      }
    }, {
      key: 'initializeDirtiness',
      value: function initializeDirtiness(model) {
        var relations = [];
        var relationsObserver = [];
        var attrs = [];

        model.eachRelationship(function (name, descriptor) {
          if (descriptor.kind === 'hasMany') {
            relations.push(descriptor.key);
            relationsObserver.push(descriptor.key + '.content.@each.id');
          } else {
            relations.push(descriptor.key);
            relationsObserver.push(descriptor.key + '.content');
          }
        });

        model.eachAttribute(function (name) {
          return attrs.push(name);
        });

        var hasDirtyRelations = function hasDirtyRelations() {
          var changed = model.changed();
          return !!relations.find(function (key) {
            return changed[key];
          });
        };

        var hasDirtyAttributes = function hasDirtyAttributes() {
          var changed = model.changed();
          return !!attrs.find(function (key) {
            return changed[key];
          });
        };

        var isDirty = function isDirty() {
          return model.get('hasDirtyAttributes') || model.get('hasDirtyRelations');
        };

        _ember.default.defineProperty(model, 'hasDirtyAttributes', _ember.default.computed.apply(_ember.default, attrs.concat([hasDirtyAttributes])));

        _ember.default.defineProperty(model, 'hasDirtyRelations', _ember.default.computed.apply(_ember.default, relationsObserver.concat([hasDirtyRelations])));

        _ember.default.defineProperty(model, 'isDirty', _ember.default.computed.apply(_ember.default, ['hasDirtyAttributes', 'hasDirtyRelations', isDirty]));
      }
    }]);

    return Tracker;
  }();

  exports.default = Tracker;
});