define('ember-cli-crudities/mixins/with-validation', ['exports', 'ember-cli-crudities/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _do_get_model: function _do_get_model(value) {
      if (value.hasOwnProperty('isFulfilled')) {
        return value.get('content');
      }
      return value;
    },
    _do_method: function _do_method(record, method, resolve, reject) {
      if (method === 'save') {
        if (record.get('isSoftDeleted') !== true) {
          record[method]().then(resolve, reject);
        } else {
          record.destroyRecord().then(resolve, reject);
        }
      } else if (method === 'validate') {
        resolve(record);
      } else {
        record.set('isSoftDeleted', false);
        record[method]().then(resolve, reject);
      }
    },
    _do_for_fields: function _do_for_fields(saved_record, fieldsets, method, top_promises, models, prefix, backlog) {
      var _this = this;

      var breakException = {};
      fieldsets.forEach(function (fieldset) {
        if (fieldset.fields !== undefined) {
          fieldset.fields.forEach(function (field) {
            if (field.widget && field.widget.split('-')[0] === 'tomany') {
              top_promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
                var promises = [];
                var appConfig = Ember.getOwner(_this).resolveRegistration('config:environment');
                var foundSaveMe = Ember.get(appConfig, 'ember-cli-crudities.forceSaveUnchanged');
                if (!foundSaveMe) {
                  try {
                    _this.get('saveMes').forEach(function (saveMe) {
                      var parts = [];
                      saveMe.split('.').forEach(function (part) {
                        parts.push(part);
                        var name = parts.join('.');
                        if (name === '' + prefix + field.name) {
                          foundSaveMe = true;
                          throw breakException;
                        }
                      });
                    });
                  } catch (e) {
                    if (e !== breakException) {
                      throw e;
                    }
                  }
                }
                if (foundSaveMe) {
                  saved_record.get(field.name).then(function (related) {
                    related.forEach(function (related_record) {
                      if (models.indexOf(related_record) === -1 || backlog.indexOf(related_record) !== 1) {
                        promises.push(new Ember.RSVP.Promise(function (resolve_bottom, reject_bottom) {
                          _this._do_for_all(related_record, [field], method, method === 'save' && field.extra && field.extra.saveTwice, field.name ? '' + prefix + field.name + '.' : prefix, backlog, false).then(function (saved_related) {
                            models.push(saved_related);
                            resolve_bottom(related_record);
                            Ember.set(fieldset, 'isError', false);
                          }, function (err) {
                            Ember.set(fieldset, 'isError', true);
                            reject_bottom(err);
                          });
                        }));
                      }
                    });
                  }, reject);
                }
                Ember.RSVP.allSettled(promises).then(function (result) {
                  var allGood = true;
                  result.forEach(function (r) {
                    if (r.state === 'rejected') {
                      allGood = false;
                    }
                  });
                  // Upstream bug?
                  // sometimes the array received as `result` is empty
                  // this isn't the best fix but it works
                  if (result.length !== promises.length) {
                    promises.forEach(function (promise) {
                      promise.then(resolve, function (err) {
                        Ember.set(fieldset, 'isError', true);
                        reject(err);
                      });
                    });
                  } else if (allGood) {
                    resolve(saved_record);
                  } else {
                    reject(result);
                  }
                }, reject);
              }));
            } else if (field.widget && field.widget === 'foreignkey' && field.extra && field.extra.expand) {
              saved_record.get(field.name).then(function (related_record) {
                top_promises.push(new Ember.RSVP.Promise(function (resolve_bottom, reject_bottom) {
                  _this._do_for_all(related_record, [field], method, method === 'save' && field.extra && field.extra.saveTwice, field.name ? '' + prefix + field.name + '.' : prefix, backlog, false).then(function (saved_related) {
                    models.push(saved_related);
                    resolve_bottom(related_record);
                    Ember.set(fieldset, 'isError', false);
                  }, function (err) {
                    Ember.set(fieldset, 'isError', true);
                    reject_bottom(err);
                  });
                }));
              });
            } else if (field.widget && field.widget === 'fieldset') {
              _this._do_for_fields(saved_record, [field], method, top_promises, models, prefix);
            } else if (field.widget && field.widget === 'tabset') {
              _this._do_for_fields(saved_record, field.tabs, method, top_promises, models, prefix);
            }
          });
          if (method === 'validate') {
            top_promises.push((0, _validation.validate_model)(saved_record, fieldset.fields));
          }
        } else if (method === 'validate') {
          top_promises.push((0, _validation.validate_model)(saved_record, fieldset));
        }
      });
    },
    _check_pending_rels: function _check_pending_rels(record, fieldsets) {
      var pending = [];
      fieldsets.forEach(function (fieldset) {
        if (fieldset.fields !== undefined) {
          fieldset.fields.forEach(function (field) {
            if (field.widget && field.widget === 'foreignkey') {
              if (Ember.get(record, field.name + '.isNew')) {
                pending.push(Ember.get(record, field.name));
              }
            } else if (field.widget && field.widget.split('-')[0] === 'manytomany') {
              Ember.get(record, field.name).forEach(function (related) {
                if (Ember.get(related, 'isNew')) {
                  pending.push(Ember.get(record, field.name));
                }
              });
            }
          });
        }
      });
      return pending;
    },
    _do_for_all: function _do_for_all(record, fieldsets, method, twice, prefix, backlog, is_top) {
      var _this2 = this;

      if (is_top === undefined) {
        is_top = true;
      }
      if (prefix === undefined) {
        prefix = '';
      }
      if (backlog === undefined) {
        backlog = [];
      }
      var top_promises = [];
      var models = [];
      var mybacklog = [];
      /** TODO: Can probably be improved with ember-concurency */
      var rv = new Ember.RSVP.Promise(function (resolve_top, reject_top) {
        var promise = void 0;
        if (method === 'save') {
          mybacklog = _this2._check_pending_rels(record, fieldsets);
        }
        if (method === 'rollbackAttributes') {
          promise = new Ember.RSVP.Promise(function (resolve) {
            if (!Ember.isEmpty(record)) {
              record[method]();
              record.set('isSoftDeleted', false);
            }
            resolve(record);
          });
        } else if (!twice || record.get('isNew')) {
          if (mybacklog.length === 0) {
            if (record !== null) {
              promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (record.hasOwnProperty('isFulfilled')) {
                  record.then(function (r) {
                    _this2._do_method(r, method, resolve, reject);
                  }, reject);
                } else {
                  _this2._do_method(record, method, resolve, reject);
                }
              });
            }
            var index = backlog.indexOf(record);
            if (index !== -1) {
              backlog.splice(index, 1);
            }
          } else {
            if (backlog.indexOf(record) === -1) {
              backlog.push(record);
            }
            promise = Ember.RSVP.resolve(record);
          }
        } else {
          promise = Ember.RSVP.resolve(record);
        }
        if (promise !== undefined) {
          promise.then(function (saved_record) {
            models.push(saved_record);
            _this2._do_for_fields(saved_record, fieldsets, method, top_promises, models, prefix, mybacklog);
            Ember.RSVP.allSettled(top_promises).then(function (result) {
              var allGood = true;
              result.forEach(function (r) {
                if (r.state === 'rejected') {
                  allGood = false;
                }
              });
              if (!allGood) {
                console.error('error in related records');
              }
              resolve_top(record);
            }, reject_top);
          }, reject_top);
        } else {
          resolve_top(record);
        }
      });
      if (twice) {
        if (mybacklog.length === 0) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            rv.then(function (record) {
              record[method]().then(function (saved_record) {
                var jndex = backlog.indexOf(record);
                if (jndex !== -1) {
                  backlog.splice(jndex, 1);
                }
                resolve(saved_record);
              }, reject);
            }, reject);
          });
        }
        if (backlog.indexOf(record) === -1) {
          backlog.push(record);
        }
      }
      if (mybacklog.length === 0) {
        var jndex = backlog.indexOf(record);
        if (jndex !== -1) {
          backlog.splice(jndex, 1);
        }
      } else {
        if (backlog.indexOf(record) === -1) {
          backlog.push(record);
        }
      }
      if (backlog.length > 0) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          rv.then(function () {
            Ember.run.later(_this2, function () {
              _this2._do_for_all(record, fieldsets, method, twice, prefix, backlog, true).then(resolve, reject);
            }, 250);
          }, reject);
        });
      }
      return rv;
    },
    validate: function validate(callback, errorHandling) {
      return this._do_for_all(this.get('model'), this.get('fieldsets'), 'validate').then(callback, errorHandling);
    }
  });
});