define('ember-toolbars/components/toolbar-manager', ['exports', 'ember', 'ember-toolbars/templates/components/toolbar-manager', 'ember-toolbars/transitions/swap-out', 'ember-toolbars/transitions/move-over', 'ember-toolbars/transitions/adapt-margin'], function (exports, _ember, _emberToolbarsTemplatesComponentsToolbarManager, _emberToolbarsTransitionsSwapOut, _emberToolbarsTransitionsMoveOver, _emberToolbarsTransitionsAdaptMargin) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberToolbarsTemplatesComponentsToolbarManager['default'],
    classNames: ['ember-toolbars'],
    animationDuration: 500,

    leftRules: _ember['default'].computed('animationDuration', function () {
      var opts = {
        adjust: [{ element: this.$(), property: 'margin-left' }],
        duration: this.get('animationDuration')
      };

      return function leftRules() {
        this.transition(this.onInitialRender(), this.use(_emberToolbarsTransitionsAdaptMargin['default'], 'x', opts));
        this.transition(this.fromValue(false), this.toValue(true), this.use(_emberToolbarsTransitionsMoveOver['default'], 'x', 1, opts), this.reverse(_emberToolbarsTransitionsMoveOver['default'], 'x', -1, opts));
        this.transition(this.fromValue(true), this.toValue(true), this.use(_emberToolbarsTransitionsSwapOut['default'], 'x', 1, opts));
      };
    }),

    rightRules: _ember['default'].computed('animationDuration', function () {
      var opts = {
        adjust: [{ element: this.$(), property: 'margin-right' }],
        duration: this.get('animationDuration')
      };

      return function rightRules() {
        this.transition(this.onInitialRender(), this.use(_emberToolbarsTransitionsAdaptMargin['default'], 'x', opts));
        this.transition(this.fromValue(true), this.toValue(false), this.use(_emberToolbarsTransitionsMoveOver['default'], 'x', 1, opts), this.reverse(_emberToolbarsTransitionsMoveOver['default'], 'x', -1, opts));
        this.transition(this.fromValue(true), this.toValue(true), this.use(_emberToolbarsTransitionsSwapOut['default'], 'x', -1, opts));
      };
    }),

    topRules: _ember['default'].computed('animationDuration', function () {
      var $elt = this.$();
      var opts = {
        adjust: [{ element: $elt, property: 'margin-top' }, { element: $elt.children('.cst-left'), property: 'translateY' }, { element: $elt.children('.cst-right'), property: 'translateY' }],
        duration: this.get('animationDuration')
      };
      return function topRules() {
        this.transition(this.onInitialRender(), this.use(_emberToolbarsTransitionsAdaptMargin['default'], 'y', opts));
        this.transition(this.fromValue(false), this.toValue(true), this.use(_emberToolbarsTransitionsMoveOver['default'], 'y', 1, opts), this.reverse(_emberToolbarsTransitionsMoveOver['default'], 'y', -1, opts));
        this.transition(this.fromValue(true), this.toValue(true), this.use(_emberToolbarsTransitionsSwapOut['default'], 'y', 1, opts));
      };
    }),

    bottomRules: _ember['default'].computed('animationDuration', function () {
      var $elt = this.$();
      var opts = {
        adjust: [{ element: $elt, property: 'margin-bottom' }, { element: $elt.children('.cst-left'), property: 'bottom' }, { element: $elt.children('.cst-right'), property: 'bottom' }],
        duration: this.get('animationDuration')
      };
      return function bottomRules() {
        this.transition(this.onInitialRender(), this.use(_emberToolbarsTransitionsAdaptMargin['default'], 'y', opts));
        this.transition(this.fromValue(true), this.toValue(false), this.use(_emberToolbarsTransitionsMoveOver['default'], 'y', 1, opts), this.reverse(_emberToolbarsTransitionsMoveOver['default'], 'y', -1, opts));
        this.transition(this.fromValue(true), this.toValue(true), this.use(_emberToolbarsTransitionsSwapOut['default'], 'y', -1, opts));
      };
    })

  });
});