define("squishable-container/raf", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.requestAnimationFrame = requestAnimationFrame;
  exports.cancelAnimationFrame = cancelAnimationFrame;
  function requestAnimationFrame(callback) {
    if (window.requestAnimationFrame) {
      return window.requestAnimationFrame(callback);
    }
    return setTimeout(callback, 33);
  }

  function cancelAnimationFrame(which) {
    if (window.cancelAnimationFrame) {
      return window.cancelAnimationFrame(which);
    }
    return clearTimeout(which);
  }
});